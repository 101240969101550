<template>
  <article class="my-info">
    <h2>기본 정보</h2>
    <div class="comm-tb-view">
      <table>
        <colgroup>
          <col style="width: 274px">
          <col style="width: auto">
        </colgroup>
        <tbody>
          <tr>
            <th>이름</th>
            <td>{{ profile.name }}</td>
          </tr>
          <tr>
            <th>휴대전화번호</th>
            <td>
              {{ profile.phoneNumber === null ? "휴대폰 미인증" : profile.phoneNumber }}
              <button
                class="btn-tb-comm"
                @click.prevent="openVerification"
              >
                {{ profile.phoneNumber === null ? "인증하기" : "변경하기" }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2>자격 정보</h2>
    <div class="comm-tb-view">
      <table>
        <colgroup>
          <col style="width: 274px">
          <col style="width: auto">
        </colgroup>
        <tbody>
          <tr>
            <th>의사면허번호</th>
            <td>{{ profile.hcpLicenseNumber }}</td>
          </tr>
          <tr>
            <th>진료과</th>
            <td>
              <div class="select-wrap">
                <select v-model="profile.hcpMedicalDept">
                  <option
                    v-for="(item, index) in medicalDept"
                    :key="index"
                    :value="item.code"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <th>근무처명</th>
            <td>
              {{ profile.hcpWorkPlace }}
            </td>
          </tr>
          <tr>
            <th>근무처주소</th>
            <td>
              {{ profile.hcpWorkAddress }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2>선택 정보</h2>
    <div class="comm-tb-view">
      <table>
        <colgroup>
          <col style="width: 274px">
          <col style="width: auto">
        </colgroup>
        <tbody>
          <tr>
            <th>근무형태</th>
            <td>
              <div class="flex-l">
                <div class="radio-wrap">
                  <input
                    id="formService01"
                    v-model="profile.hcpWorkType"
                    type="radio"
                    name="formServiceType"
                    value="1"
                  >
                  <label for="formService01">개원의</label>
                </div>
                <div class="radio-wrap">
                  <input
                    id="formService02"
                    v-model="profile.hcpWorkType"
                    type="radio"
                    name="formServiceType"
                    value="2"
                  >
                  <label for="formService02">봉직의</label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>전문의 여부</th>
            <td>
              <div class="flex-l">
                <div class="radio-wrap">
                  <input
                    id="specialistStatus01"
                    v-model="profile.hcpSpecType"
                    type="radio"
                    name="specialistStatusType"
                    value="2"
                  >
                  <label for="specialistStatus01">전문의</label>
                </div>
                <div class="radio-wrap">
                  <input
                    id="specialistStatus02"
                    v-model="profile.hcpSpecType"
                    type="radio"
                    name="specialistStatusType"
                    value="1"
                  >
                  <label for="specialistStatus02">일반의</label>
                </div>
              </div>
            </td>
          </tr>

          <tr
            v-show="profile.hcpSpecType == 2"
            class="specialistStatus-01"
          >
            <th><label>전공 과목</label></th>
            <td>
              <div class="select-wrap">
                <select v-model="profile.hcpMajor">
                  <option
                    v-for="(item, index) in majer"
                    :key="index"
                    :value="item.code"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </div>
            </td>
          </tr>

          <tr
            v-show="profile.hcpSpecType == 2"
            class="specialistStatus-01"
          >
            <th><label>전공취득년도</label></th>
            <td>
              <div class="text-wrap">
                <input
                  v-model="profile.hcpSpecYear"
                  v-number
                  type="text"
                  placeholder="직접 입력"
                  maxlength="4"
                >
              </div>
            </td>
          </tr>

          <tr
            v-show="profile.hcpSpecType == 1"
            class="specialistStatus-02"
          >
            <th><label>의사면허취득년도</label></th>
            <td>
              <div class="text-wrap">
                <input
                  v-model="profile.hcpLicenseYear"
                  v-number
                  type="text"
                  placeholder="직접 입력"
                  maxlength="4"
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex-r">
      <button class="btn-withdrawal">
        <router-link :to="{ path: 'withdrawal' }">
          의사랑 계정 연결 해제
        </router-link>
      </button>
    </div>

    <div class="btn-2-wrap">
      <button
        class="btn-next"
        @click="saveProfile"
      >
        저장
      </button>
    </div>

    <popup-verification
      ref="popupVerification"
      @result="verifyResult"
    />

    <div v-show="false">
      <form
        ref="form"
        method="post"
        action="#"
      >
        <input
          type="hidden"
          name="reqInfo"
          :value="encStr"
        >
        <input
          type="hidden"
          name="retUrl"
          :value="retUrl"
        >
        <input
          type="hidden"
          name="verSion"
          :value="version"
        >
      </form>
    </div>
  </article>
</template>

<script>
import axios from '@axios'
import PopupVerification from '@/views/apps/popup/PopupVerification.vue'

export default {
  components: {
    PopupVerification
  },
  data() {
    return {
      profile: {
        id: null,
        name: null,
        phoneNumber: null,
        hcpLicenseNumber: null,
        hcpMedicalDept: null,
        hcpWorkPlace: null,
        hcpWorkAddress: null,
        hcpWorkType: null,
        hcpSpecType: null,
        hcpMajor: null,
        hcpSpecYear: null,
        hcpLicenseYear: null,
      },
      medicalDept: [],
      majer: [],
      originProfile: {},
      retUrl: null,
      encStr: null,
      reqNum: null,
    }
  },
  created() {
    this.getMedicalDept()
    this.getProfile()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'verification-result') {
      const { retInfo } = to.query

      this.$store.commit('util/setVerifyResult', retInfo)

      next(false)
    } else if (JSON.stringify(this.originProfile) !== JSON.stringify(this.profile)) {
      this.$parent.showConfirmDialog('변경된 내용을 저장하시겠습니까?', confirm => {
        if (confirm) {
          axios.put(`/fu/user/profile`,
            this.profile)
            .then(() => {
              this.$parent.showAlertDialog('정상적으로 변경되었습니다.', () => {
                next()
              })
            })
            .catch(error => {
              this.$parent.showAlertDialog(error.response.data)
            })
        } else next()
      })
    } else next()
  },
  methods: {
    getMedicalDept() {
      axios.get(`/fu/user/profile/code`, {
      })
        .then(rs => {
          this.medicalDept = rs.data.hcpMedicalDept
          this.majer = rs.data.hcpMajor
        })
        .catch(error => {
          this.$parent.showAlertDialog(error.response.data)
        })
    },
    getProfile() {
      axios.get(`/fu/user/profile`, {
      })
        .then(rs => {
          this.profile = rs.data
          this.originProfile = { ...this.profile }
        })
        .catch(error => {
          this.$parent.showAlertDialog(error.response.data)
        })
    },
    saveProfile() {
      const regex = /(^(1[9]|2[0])\d{2})$/

      if (this.profile.hcpMedicalDept == null) {
        this.$parent.showAlertDialog('필수 항목은 반드시 입력하셔야 합니다.')
      } else if (this.profile.hcpSpecType !== null && this.profile.hcpSpecType.toString() === '1' && this.profile.hcpLicenseYear !== null && this.profile.hcpLicenseYear !== '' && !regex.test(this.profile.hcpLicenseYear)) {
        this.$parent.showAlertDialog('잘못된 년도 형식입니다.')
      } else if (this.profile.hcpSpecType !== null && this.profile.hcpSpecType.toString() === '2' && this.profile.hcpSpecYear !== null && this.profile.hcpSpecYear !== '' && !regex.test(this.profile.hcpSpecYear)) {
        this.$parent.showAlertDialog('잘못된 년도 형식입니다.')
      } else {
        axios.put(`/fu/user/profile`,
          this.profile)
          .then(() => {
            this.originProfile = { ...this.profile }

            this.$parent.showAlertDialog('정상적으로 변경되었습니다.')
          })
          .catch(error => {
            this.$parent.showAlertDialog(error.response.data)
          })
      }
    },
    verifyResult(result) {
      this.profile.phoneNumber = result.cellNo.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
      this.originProfile.phoneNumber = result.cellNo.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
    },
    openVerification() {
      this.$refs.popupVerification.open()
    },
  },
}
</script>
